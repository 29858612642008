import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import ToaTreasureGenerator from '@/components/ToaTreasureGenerator'

const ToaTreasureGeneratorPage = () => (
  <Layout>
    <Seo
      title='Гробница аннигиляции: генератор выпавших сокровищ'
      description='Dungeons & Dragons, Гробница аннигиляции: генератор выпавших сокровищ'
    />
    <ToaTreasureGenerator/>
  </Layout>
)

export default ToaTreasureGeneratorPage
