export default diceType => (diceCount = 1) => {
  let summ = 0

  while (diceCount) {
    summ += Math.floor(Math.random() * diceType)
    diceCount--
  }

  return summ
}
