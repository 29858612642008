const treasureList = [
  {
    id: 'nothing',
    probabilityWeight: 50,
    description: `Ничего`,
  },
  {
    id: 'map',
    probabilityWeight: 2,
    description: `Грубая карта, нарисованная на клочке шкуры динозавра, показывающая маршрут в Порт Нянзару и местоположение случайно определённого ориентира или логова (250 зм)`,
  },
  {
    id: 'gems',
    probabilityWeight: 2,
    description: `1к4 драгоценных камней (по 10 зм каждый) в небольшом кошельке`,
  },
  {
    id: 'ring',
    probabilityWeight: 2,
    description: `Малахитовое кольцо, инкрустированное электриумом (50 зм)`,
  },
  {
    id: 'flacon',
    probabilityWeight: 2,
    description: `Пустой закупоренный флакон, сделанный из кости крыла ааракокры, с гравировкой (5 зм)`,
  },
  {
    id: 'rotten_quiver',
    probabilityWeight: 2,
    description: `Гниющий колчан, содержащий 1к10 посеребрённых стрел`,
  },
  {
    id: 'gold_piece',
    probabilityWeight: 2,
    description: `Золотой самородок размером с кулак дварфа (50 зм)`,
  },
  {
    id: 'snake_poison',
    probabilityWeight: 2,
    description: `Пробирка с ядом змеи (см. главу 8 Руководства Мастера)`,
  },
  {
    id: 'smoke_pipe',
    probabilityWeight: 2,
    description: `Курительная трубка из слоновой кости, с гравировкой (25 зм)`,
  },
  {
    id: 'antisect',
    probabilityWeight: 2,
    description: `Коробка, содержащая 2к12 блоков благовоний от насекомых`,
  },
  {
    id: 'yepteka',
    probabilityWeight: 2,
    description: `Железный жетон с символом трицератопса (знак Общества Йетепка), в Порту Нянзару стоит 50 зм`,
  },
  {
    id: 'potion_animal_friendship',
    probabilityWeight: 2,
    description: `Зелье дружбы с животными`,
  },
  {
    id: 'potion_survival',
    probabilityWeight: 2,
    description: `Зелье живучести`,
  },
  {
    id: 'spell_scroll',
    probabilityWeight: 2,
    description: `Свиток заклинания (выберите заклинание 1-го уровня)`,
  },
  {
    id: 'wywern_poison',
    probabilityWeight: 2,
    description: `Пробирка с ядом виверны (см. главу 8 Руководства Мастера)`,
  },
  {
    id: 'obsidian_knife',
    probabilityWeight: 2,
    description: `Обсидиановый нож (25 зм)`,
  },
  {
    id: 'pipe_with_gems',
    probabilityWeight: 2,
    description: `Закупоренная, деревянная трубка, содержащая 1к4 драгоценных камней (по 50 зм каждый)`,
  },
  {
    id: 'onyx_stone',
    probabilityWeight: 2,
    description: `Маленький ониксовый шар с лабиринтовыми узорами, вырезанными на его поверхности (75 зм), можно использовать как арканную или друидскую фокусировки`,
  },
  {
    id: 'batiri_mask',
    probabilityWeight: 2,
    description: `Маска гоблинов Батири из раскрашенного дерева, украшенная девятью драгоценными камнями (по 10 зм каждый)`,
  },
  {
    id: 'spyglass',
    probabilityWeight: 2,
    description: `Подзорная труба (1000 зм)`,
  },
  {
    id: 'lute',
    probabilityWeight: 2,
    description: `Лютня (35 зм) с одной порванной струной`,
  },
  {
    id: 'tools_thief',
    probabilityWeight: 2,
    description: `Набор воровских инструментов (25 зм)`,
  },
  {
    id: 'lock',
    probabilityWeight: 2,
    description: `Отличный замок (50 зм), который требует успешной проверки Ловкости СЛ 20 для вскрытия с использованием воровских инструментов (ключ от замка отсутствует)`,
  },
  {
    id: 'Keoghtom',
    probabilityWeight: 2,
    description: `Баночка с мазью Кеогтома [Keoghtom’s ointment]`,
  },
  {
    id: 'horn_of_blasting',
    probabilityWeight: 2,
    description: `Рог взрыва [Horn of blasting]`,
  },
  {
    id: 'wand_of_secrets',
    probabilityWeight: 2,
    description: `Волшебная палочка секретов [Wand of secrets]`,
  },
]

export default treasureList
