import React from "react"

import SimpleTableGenerator from '@/components/SimpleTableGenerator'

import treasureList from './constants/treasureList'

export default () => (
  <SimpleTableGenerator
    title='Генератор выпавших сокровищ для кампании Гробницы Аннигиляции'
    list={treasureList}
  />
)
