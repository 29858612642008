import React from 'react'
import PropTypes from 'prop-types'

import BtnGenerate from "@/components/BtnGenerate"
import PageTitle from "@/components/PageTitle"
import Markdown from "@/components/Markdown"

import generateTextLinks from "@/utils/generateTextLinks"

import './SimpleTableGenerator.less'

const SimpleTableGeneratorComponent = (
  {
    children,
    diceRollList,
    generate,
    rollCount,
    rollCountMax,
    showDiceRolls,
    showRowNumber,
    showTableHeader,
    title,
    updateRollCount,
  }
) => (
  <section className='SimpleTableGenerator'>
    <PageTitle>{title}</PageTitle>

    <div className='SimpleTableGenerator_controls'>
      {children}

      <label className='SimpleTableGenerator_label'>
        <p>Сгенерировать раз: {rollCount}</p>
        <input
          className='SimpleTableGenerator_range'
          type='range'
          min='1'
          max={rollCountMax}
          value={rollCount}
          onChange={updateRollCount}
        />
      </label>

      <BtnGenerate
        onClick={generate}
        className='SimpleTableGenerator_btnGenerate'
      />
    </div>

    <table className='SimpleTableGenerator_table'>
      {
        showTableHeader
          ? (
            <thead className='SimpleTableGenerator_thead'>
            <tr className='SimpleTableGenerator_row'>
              {
                showDiceRolls
                  ? (
                    <th className='SimpleTableGenerator_cell SimpleTableGenerator_cell-head'>
                      Бросок к100
                    </th>
                  )
                  : null
              }
              {
                showRowNumber
                  ? (
                    <th className='SimpleTableGenerator_cell SimpleTableGenerator_cell-head'>
                      №
                    </th>
                  )
                  : null
              }
              <th className='SimpleTableGenerator_cell SimpleTableGenerator_cell-head'>
                Результат
              </th>
            </tr>
            </thead>
          )
          : null
      }

      <tbody>
      {
        diceRollList.map(
          ({diceRoll, treasure: {id, description}}, i) => (
            <tr
              className={`SimpleTableGenerator_row SimpleTableGenerator_row-${id}`}
              key={`${i}_${diceRoll}_${id}`}
            >
              {
                showDiceRolls
                  ? <td className='SimpleTableGenerator_cell'>{diceRoll}</td>
                  : null
              }
              {
                showRowNumber
                  ? (
                    <td className='SimpleTableGenerator_cell SimpleTableGenerator_cell-rowNum'>
                      {i + 1}
                    </td>
                  )
                  : null
              }
              <td className='SimpleTableGenerator_cell'>
                <Markdown>{generateTextLinks(description)}</Markdown>
              </td>
            </tr>
          )
        )
      }
      </tbody>
    </table>
  </section>
)

SimpleTableGeneratorComponent.defaultProps = {
  children: null,
  showDiceRolls: true,
  showRowNumber: false,
  showTableHeader: true,
}

SimpleTableGeneratorComponent.propTypes = {
  children: PropTypes.any,
  diceRollList: PropTypes.array.isRequired,
  generate: PropTypes.func.isRequired,
  rollCount: PropTypes.number.isRequired,
  rollCountMax: PropTypes.number.isRequired,
  showDiceRolls: PropTypes.bool,
  showRowNumber: PropTypes.bool,
  showTableHeader: PropTypes.bool,
  title: PropTypes.string.isRequired,
  updateRollCount: PropTypes.func.isRequired,
}

export default SimpleTableGeneratorComponent
