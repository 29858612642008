import React from "react"

import './BtnGenerateStyles.less'

const BtnGenerate = ({onClick, className = ''}) => (
  <button
    className={`BtnGenerate ${className}`}
    onClick={onClick}
  >
    Сгенерировать
  </button>
)

export default BtnGenerate
